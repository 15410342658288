<template>
  <el-container class="shopList ">
    <el-header class="box add">
       <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>个人中心</el-breadcrumb-item>
       <el-breadcrumb-item>完善信息</el-breadcrumb-item>
    </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt15">
     <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item title="用户基本信息" name="1">
           <el-form :inline="true"  status-icon ref="form1"  :model="form1"  class="demo-ruleForm">
     <el-row class="box mt20  f14">
        <el-col :span="11" class="tl">
        <el-form-item  label="姓名：" size="medium" class="w" label-width="28%"  prop="user_name" :rules="[{required: true,message: '请输入姓名', trigger: 'blur' },{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' },{ maxlength: 50, message: '最多输入50个字符' }]">
           <el-input placeholder="姓名" maxlength='30' clearable v-model="form1.user_name" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
         <el-col :span="11" class="tl" >
        <el-form-item  label="性别：" size="medium" class="w" label-width="28%"  prop="user_sex" :rules="[{required: true,message: '请输入性别', trigger: 'blur' }]">
           <el-select v-model="form1.user_sex" clearable placeholder="性别" >
                <el-option
                  v-for="(item,index) in options"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="22" class="tl" >
        <el-button   class="ml_5"  size="medium" type="primary" @click="submitForm('form1')">提交</el-button>
        </el-col>
     </el-row>
        </el-form>    
  </el-collapse-item>
  <el-collapse-item title="密码更新" name="2">
     <el-form :inline="true"  status-icon ref="form2"  :model="form2"  class="demo-ruleForm">
     <el-row class="box mt20  f14">
          <el-col :span="11" class="tl">
        <el-form-item  label="旧密码：" size="medium" class="w" label-width="28%"  prop="user_loginpass_old" :rules="[{required: true,message: '请输入旧密码', trigger: 'blur' },{ pattern: /^[A-Za-z0-9]+$/, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'},{ min: 4, max: 15, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'}]">
           <el-input type="password" placeholder="旧密码" minlength="4" maxlength='15' clearable v-model="form2.user_loginpass_old" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
          <el-col :span="11" class="tl" >
        <el-form-item  label="新密码：" size="medium" class="w" label-width="28%"  prop="user_loginpass"  :rules="[{required: true,message: '请输入新密码', trigger: 'blur' },{ pattern: /^[A-Za-z0-9]+$/, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'},{ min: 4, max: 15, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'}]">
           <el-input type="password" placeholder="新密码" minlength="4" maxlength='15' clearable v-model="form2.user_loginpass" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="11" class="tl">
        <el-form-item  label="确认密码：" size="medium" class="w" label-width="28%"  prop="user_loginpass_re"  :rules="[{required: true,message: '请再次输入新密码', trigger: 'blur' },{ pattern: /^[A-Za-z0-9]+$/, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'},{ min: 4, max: 15, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'}]">
           <el-input type="password" placeholder="确认密码" minlength="4" maxlength='15' clearable v-model="form2.user_loginpass_re" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="22" class="tl">
        <el-button   class="ml_5"  size="medium" type="primary" @click="submitForm1('form2')">提交</el-button>
        </el-col>
     </el-row>
        </el-form>  
  </el-collapse-item>
  <el-collapse-item title="账号更新" name="3">
     <el-form :inline="true"  status-icon ref="form3"  :model="form3"  class="demo-ruleForm">
     <el-row class="box mt20  f14">
        <el-col :span="11" class="tl" >
        <el-form-item  label="登录账号：" size="medium" class="w" label-width="28%"  prop="user_mobilephone" :rules="[{required: true,message: '请输入登录账号', trigger: 'blur' },{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' },{ maxlength: 50, message: '最多输入50个字符' }]">
           <el-input placeholder="登录账号" maxlength='30'  clearable v-model="form3.user_mobilephone" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="11" class="tl" >
           <el-button icon="el-icon-mobile-phone" @click="takeNum('form3')" slot="append" type="primary" :disabled="disabled=!show" >  
                <span v-show="show">获取验证码</span>
                <span v-show="!show" class="count">{{count}} s</span>
            </el-button>
        </el-col>
         <el-col :span="11" class="tl">
           <el-form-item  label="验证码：" size="medium" class="w" label-width="28%"  prop="mess_code" :rules="[{required: true,message: '请输入验证码', trigger: 'blur' },{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' },{ maxlength: 50, message: '最多输入50个字符' }]">
           <el-input placeholder="验证码" maxlength='30' clearable v-model="form3.mess_code" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="22" class="tl" >
        <el-button   class="ml_5"  size="medium" type="primary" @click="submitForm2('form3')">提交</el-button>
        </el-col>
     </el-row>
        </el-form>  
  </el-collapse-item>
  <el-collapse-item title="企业登记" name="4">
      <el-form :inline="true"  status-icon ref="form4"  :model="form4"  class="demo-ruleForm">
     <el-row class="box mt20  f14">
       <el-col :span="11" class="tl" >
            <el-form-item size="medium" label="企业名称：" class="w" label-width="28%" >
                <el-input disabled placeholder="企业名称" maxlength='30' clearable v-model="form4.company_name" class="input-with-select">
            </el-input>
            </el-form-item>
          </el-col>
        <el-col :span="11" class="tl">
          <el-form-item  label="监管类型：" size="medium" class="w" label-width="28%" >
                <el-select disabled v-model="form4.company_type_id" filterable >
            <el-option  v-for="item in typetOptions" :key="item.company_type_id" :label="item.company_type_name" :value="String(item.company_type_id)" ></el-option>
          </el-select>
        </el-form-item>
        </el-col>
         <el-col :span="11" class="tl" >
            <el-form-item size="medium" label="主体类型：" class="w" label-width="28%" >
              <el-select disabled v-model="form4.company_tag_id" filterable >
                <el-option  v-for="(item,index) in tagOptions" :key="index" :label="item.company_tag_name" :value="String(item.company_tag_id)" ></el-option>
                 </el-select>
            </el-form-item>
          </el-col>
           <el-col   :span="11" class="tl">
           <el-form-item class="w" label-width="28%" label="统一社会信用代码：" size="medium"  prop="credit_code" :rules="[{required: true, message: '请输入统一社会信用代码', trigger: 'blur' },{ min: 18,max: 18, message: '请如实填写18位统一社会信用代码，以供核对', trigger: 'blur' }]">
           <el-input placeholder="请输入统一社会信用代码"  clearable v-model="form4.credit_code" class="input-with-select">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" class="tl" >
        <el-form-item class="w" label-width="28%" label="法人姓名：" size="medium"  prop="legal_person_name" :rules="[{required: true,message: '请输入经营者姓名', trigger: 'blur' },{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' }]">
         <el-input placeholder="请输入法人姓名" maxlength="10" clearable v-model="form4.legal_person_name" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
         <el-col :span="11" class="tl" >
         <el-form-item class="w" label-width="28%" label="法人电话：" size="medium"  prop="mobilephone" :rules="[{required: true,message: '请输入手机号',trigger: 'blur'},{pattern: /^1[3|4|5|7|8|9][0-9]\d{8}$/,message: '手机号格式不对',trigger: 'blur'}]">
          <el-input placeholder="请输入11位手机号码" clearable v-model="form4.mobilephone" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
         <el-col :span="11" class="tl">
         <el-form-item class="w" label-width="28%" label="身份证号：" size="medium" prop="idcard" :rules="[{required: true, message: '请输入身份证号', trigger: 'blur' },{ min: 15, max: 18, message: '请如实填写15或者18位号码，以供核对', trigger: 'blur' },{ pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,message: '请输入正确的身份证号码',trigger: 'blur'}]">
          <el-input placeholder="请输入18位身份证号码" clearable v-model="form4.idcard" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="11" class="tl" >
         <el-form-item  class="w" label-width="28%" label="登记时间：" size="medium">
          <el-date-picker type="date" clearable placeholder="选择日期" :picker-options='pickerOptionsEnd' v-model="form4.record_time"   format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="logTimeChange"></el-date-picker>
        </el-form-item>
        </el-col>
        <el-col  :span="11" class="tl">
           <el-form-item class="w" label-width="28%" label="登记证号：" size="medium"  prop="record_code" :rules="[{required: true, message: '请输入登记证号', trigger: 'blur' }]">
           <el-input placeholder="登记证号"  clearable v-model="form4.record_code" class="input-with-select">
            </el-input>
          </el-form-item>
        </el-col>
         <el-col   :span="11" class="tl" >
           <el-form-item class="w" label-width="28%" label="统一社会信用代码：" size="medium"  prop="credit_code" :rules="[{required: true, message: '请输入统一社会信用代码', trigger: 'blur' },{ max: 18, message: '请如实填写18位统一社会信用代码，以供核对', trigger: 'blur' },{ pattern:/[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}/g,message: '请输入正确的统一社会信用代码',trigger: 'blur'}]">
           <el-input placeholder="请输入统一社会信用代码"  clearable v-model="form4.credit_code" class="input-with-select">
            </el-input>
          </el-form-item>
        </el-col>
         <el-col :span="11" class="tl" >
         <el-form-item  class="w" label-width="28%" label="发证日期：" size="medium">
          <el-date-picker type="date" clearable placeholder="选择发证日期" :picker-options='pickerOptionsEnd' v-model="form4.issue_time"   format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="logTimeChange"></el-date-picker>
        </el-form-item>
        </el-col>
         <el-col :span="11" class="tl" >
         <el-form-item  class="w" label-width="28%" label="有效期：" size="medium" prop="unuseful_time" :rules="[{required: true, message: '请选择时间', trigger: 'change'}]">
          <el-date-picker type="date" clearable placeholder="选择有效期"  v-model="form4.unuseful_time"   format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="logTimeChange"></el-date-picker>
        </el-form-item>
        </el-col>
         <el-col :span="11" class="tl" >
         <el-form-item class="w" label-width="28%" label="经营场所面积：" size="medium" prop="place_area" :rules="[{required: true,message: '请输入经营场所面积', trigger: 'blur'},{ pattern: /^(^[1-9](\d+)?(\.\d{1,2})?$)|(^0\.0[1-9]{1}$)|(^0\.[1-9]{1}\d{0,1}$)/, message: '只允许输入大于0的数' }]">
          <el-input style="width:100px" placeholder="例：20"  min="1" clearable v-model="form4.place_area" class="input-with-select mr10">
            </el-input>平方米（㎡）
        </el-form-item>
        </el-col>
        <el-col :span="11" class="tl">
         <el-form-item class="w" label-width="28%" label="从业人数：" size="medium" prop="employ_num" :rules="[{required: true,message: '请输入从业人数', trigger: 'blur' },{ pattern: /^(^[1-9]{1}\d{0,6}$)*$/, message: '只允许输入最小为1的整数' }]">
          <el-input placeholder="例：20" v-model="form4.employ_num" clearable class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
         <el-col :span="22"  class="tl">
        <el-form-item class="w " label-width="14%"   label="生产形式：" size="medium" prop="produce_form_names_list" :rules="[{required: true,message: '请选择生产形式', trigger: 'blur' }]">
          <el-checkbox-group v-model="form4.produce_form_names_list" >
           <el-checkbox-button class="mr10" v-for="options in Options1" :label="options.dictionary_field" :key="options.dictionary_field">{{options.dictionary_field}}</el-checkbox-button>
          </el-checkbox-group>
        </el-form-item>
        </el-col>
         <el-col :span="22"  class="tl">
         <el-form-item class="w" label-width="14%"  label="生产类别：" size="medium" prop="produce_type_names_list" :rules="[{required: true,message: '请选择生产类别', trigger: 'blur' }]">
          <el-checkbox-group v-model="form4.produce_type_names_list" >
            <el-checkbox-button class="mr10" v-for="options in Options2" :label="options.dictionary_field" :key="options.dictionary_field">{{options.dictionary_field}}</el-checkbox-button>
          </el-checkbox-group>
        </el-form-item>
        </el-col>
         <el-col :span="22" class="tl">
         <el-form-item class="w w24" label-width="14%"   label="经营范围：" size="medium" prop="business_range_list" :rules="[{required: true,message: '请选择经营范围',trigger: ['blur','change'] }]">
          <el-checkbox-group v-model="form4.business_range_list" >
           <el-checkbox-button class="mr10" v-for="options in Options5" :label="options.dictionary_field" :key="options.dictionary_field">{{options.dictionary_field}}</el-checkbox-button>
          </el-checkbox-group>
        </el-form-item>
        </el-col>
         <el-col :span="22"  class="tl" >
         <el-form-item class="w w24" label-width="14%"   label="主营项目：" size="medium" prop="main_subject_names_list" :rules="[{required: true,message: '请选择主营项目',trigger: ['blur','change'] }]">
          <el-checkbox-group v-model="form4.main_subject_names_list" >
           <el-checkbox-button class="mr10" v-for="options in Options3" :label="options.dictionary_field" :key="options.dictionary_field">{{options.dictionary_field}}</el-checkbox-button>
          </el-checkbox-group>
        </el-form-item>
        </el-col>
         <el-col :span="22"  class="tl"  >
         <el-form-item class="w w24" label-width="14%"  label="兼营项目：" size="medium">
          <el-checkbox-group v-model="form4.sub_subject_names_list">
            <el-checkbox-button class="mr10" v-for="options in Options4" :label="options.dictionary_field" :key="options.dictionary_field">{{options.dictionary_field}}</el-checkbox-button>
          </el-checkbox-group>
        </el-form-item>
        </el-col>
        <el-row class="box mt20  f14 people-message">
       <div class="box list">
      <el-form ref="form" :model="form" >
       <el-table  ref="table" :data="form.List" class="w" empty-text='暂无数据'>
          <el-table-column label="姓名">
           <template slot-scope="scope">
            <el-form-item size="medium" :prop="'List.' + scope.$index + '.name'" :rules="[{required: true, message: '请输入姓名', trigger: 'blur' },{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' }]">
              <el-input placeholder="请输入姓名" clearable  v-model="scope.row.name"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
         <el-table-column label="健康证明">
          <el-upload class="health_url"
                ref="upload"
                multiple
                fit="fill" style="width: 70px;height: 40px"
                action="/pc/file/upload"
                 :limit="1"
                  list-type="picture-card"
                :before-upload="beforeAvatarUpload"
                accept="image/png,image/jpg,image/jpeg"
                :on-success="handlepoll11"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove11"
                :class="{disabled:uploadDisabled11}"
                >
    <el-button size="medium" type="primary">点击上传</el-button>
</el-upload>
        </el-table-column>
        
         <el-table-column label="健康证有效期">
          <template slot-scope="scope">
            <el-form-item   size="medium" :prop="'List.' + scope.$index + '.time'" :rules="[{required: true, message: '选择有效期', trigger: 'blur' }]">
          <el-date-picker type="date" clearable placeholder="选择有效期至" v-model="scope.row.time"   format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="pickerOptions"></el-date-picker>
        </el-form-item>
          </template>
        </el-table-column>
        <el-table-column  label="操作" >
          <template slot-scope="">
             <el-button  class="" size="medium" type="primary" @click="savepeople">保存</el-button>
          <el-button  class="back_btn"  size="medium" plain @click="cancle">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
      <el-table :data="form4.company_employ_list" style="width: 100%" class="no-header">
        <el-table-column  prop="company_employ_name" ></el-table-column>
        <el-table-column >
             <template slot-scope="scope">
           <el-image :src="scope.row.employ_health_url" fit="fill" :preview-src-list="[scope.row.employ_health_url]" style="width: 40px;height: 40px"></el-image>
             </template>
        </el-table-column>
        <el-table-column prop="employ_health_datedue" ></el-table-column>
        
        <el-table-column fixed="right">
          <template slot-scope="scope">
           <el-button  class="back_btn"  size="medium" plain  @click="handleDelete(scope.$index, scope.row)">删除</el-button>
         </template>
        </el-table-column>
      </el-table>

    </div> 
     </el-row>
        <el-col :span="22" class="tl">
        <el-button   class="ml_5"  size="medium" type="primary" @click="submitForm3('form4')">提交</el-button>
        </el-col>
     </el-row>
        </el-form> 
  </el-collapse-item>
</el-collapse>
</el-main>
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { local,aa,objKeySort } from '../../../util/util'
import {imageUrl} from '../../../config/index'
import { mapState } from 'vuex'
import md5 from 'js-md5';
export default {
  data () {
    return {
      imageUrl:imageUrl,
       activeNames: ['1'],
      user:'',
      timeVisible:false,
      uploadDisabled11: false,
      timer: '',
      loading:false,
      tableData:[],
      options:[{label: '男',value:'1'},{label: '女',value:'2'}],
      show: true,
      count: '',
      typetOptions:[],
      tagOptions:[],
      Options1:[],//生产形式
      Options2:[],//上产类别
      Options3:[],//主营项目
      Options4:[],//兼营项目
      Options5:[],//经营范围
      form1:{
        user_name:'',
        user_sex:''
      },
      form2:{
        user_loginpass_old:'',
        user_loginpass:'',
        user_loginpass_re:''
      },
      form3:{
        user_mobilephone:'',
        mess_code:'',
        query_param:'2fb1c5cf58867b5bbc9a1b145a86f3a0'
      },
      form4:{
        company_id:'6',
        company_name:'',
        company_type_id:'',
        company_tag_id:'',
        legal_person_name:'',
        mobilephone:'',
        idcard:'',
        record_time:'',
        record_code:'',
        issue_time:'',
        unuseful_time:'',
        place_area:'',
        employ_num:'',
        credit_code:'',
        produce_form_names_list:[],//生产形式
        produce_type_names_list:[],//生产类别
        main_subject_names_list:[],//主营项目
        sub_subject_names_list:[],//兼营项目
        business_names_list:[],//经营范围
        accessory_list:[],
        company_employ_list:[]

      },
       form: {List: [{name: '',healthimg:'',time:''}]},
       pickerOptionsEnd: {
        disabledDate: time => {
          return time.getTime() > Date.now()
        }
      },
       pickerOptions: {
         disabledDate(time) {
            return time.getTime() < Date.now();
          },
        }  
    }
  },
   created () {
     var that=this;
      that.user = local.get('user');
    that.getData({company_id:this.form4.company_id})
  },
  methods: {
       handleChange(val) {
      },
       getData (params) {
          var that=this;
          axios.get('/pc/user/single').then((v) => {
        that.user=v.data.user;
        that.form1.user_name=that.user.user_name;
        that.form1.user_sex=String(that.user.user_sex);
        that.form3.user_mobilephone=that.user.user_mobilephone
      })
       axios.get('/pc/company/single',params).then((v) => { 
             that.form4.company_type_id=String(v.data.company.company_type_id);
              that.form4.company_tag_id=String(v.data.company.company_tag_id);
               that.form4.company_id=String(v.data.company.company_id);
                 that.form4.company_name=v.data.company.company_name;
               
          this.getType()
          this.getTag(that.form4.company_type_id);
          this.getDictionary('4')
      })
    },
    submitForm(form1){
         this.$refs[form1].validate((valid) => {
        if (valid) {           
            axios.put('/pc/user/info/update', this.form1).then((v) => {
            })
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
         })
      },
      submitForm1(form2){
        let params={
          user_loginpass_old:md5(this.form2.user_loginpass_old),
          user_loginpass:md5(this.form2.user_loginpass),
          user_loginpass_re:md5(this.form2.user_loginpass_re),
        }
         this.$refs[form2].validate((valid) => {
        if (valid) {           
            axios.post('/pc/user/password/update', params).then((v) => {
            })
        }
         })
      },
      submitForm2(form3){
         this.$refs[form3].validate((valid) => {
        if (valid) {           
            axios.put('/pc/user/loginname/update', this.form3).then((v) => {
            })
        }
         })
      },
      //获取验证码
    takeNum(form3){
       let user_mobilephone=this.form3.user_mobilephone+"";
       let myThis=this;
      this.$refs[twoForm].validate((valid) => {
        this.twoForm.messcode='';
        if (valid) {
          axios.get('/pc/user/exist',{user_loginname:user_mobilephone}).then((v) => {
          if(v.code === 200){
            this.messget(user_mobilephone);
          }else{
            this.$message.error("该用户不存在");
          }  
        }).catch()
        } else {
          return false
        }
      })
    },
    messget(loginname){      
      axios.get('/pc/sms/send',{user_mobilephone:loginname,mess_type:'1'}).then((v) => {   
        this.send(v.data.mess_code);               
      }).catch()
    },
    send(messcode){
      this.twoForm.mess_code= messcode;
      let TIME_COUNT = 60;
      if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
    },
    logTimeChange(val){},
    getType(){
           var that=this;
          axios.get('/pc/company-type/all').then((v) => { 
          that.typetOptions=v.data.company_type_list;
      })
      },
      getTag(params){
           var that=this;
          axios.get('/pc/company-tag/all',{company_type_id:params}).then((v) => { 
          that.tagOptions=v.data.company_tag_list;
      })
      },
    getDictionary(params){
        var that=this;
          axios.get('/pc/dictionary/all',{company_tag_id:params}).then((v) => { 
          that.Options1=v.data.dictionary_list.经营信息.生产形式;
          that.Options2=v.data.dictionary_list.经营信息.生产类别;
          that.Options3=v.data.dictionary_list.经营信息.主营项目;
          that.Options4=v.data.dictionary_list.经营信息.兼营项目;
          that.Options5=v.data.dictionary_list.经营信息.经营范围;
      })
    },
    beforeAvatarUpload (file) {
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
      return isLt10M;
    },
    handlepoll11 (response, file, fileList) {
         this.uploadDisabled11 = true;
          this.form.List[0].healthimg=response.data.visit_url
    },
     handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
    handleRemove11(file, fileList) {
        let tmpname=this.form.List[0].name
        let tmptime=this.form.List[0].time
        this.form.List=[{name: tmpname,healthimg:'',time:tmptime}]
        //this.form.List[0].healthimg=''
        this.uploadDisabled11 = false;
      },
    cancle(){ this.form.List=[{name: '',healthimg:'',time:''}]},
     savepeople(form){
       this.$refs.form.validate(variable => {
         if (variable) {
           if(this.form.List[0].healthimg==''){
             this.$message.error('请上传证件照片');
              return false
           }else{
             this.form4.company_employ_list.push(objKeySort({
             employ_health_url:this.form.List[0].healthimg.substring(this.form.List[0].healthimg.indexOf("resources")),
             company_employ_name:this.form.List[0].name,
             employ_health_datedue:this.form.List[0].time,}
           ));
           this.form.List=[{name: '',healthimg:'',time:''}]
           this.handleRemove11()
           this.$refs.upload.clearFiles();
           }
          }else{return}
        })
       },
         handleDelete(index){ //删除行数
        this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
           this.form4.company_employ_list.splice(index, 1)
        }).catch(() => {        
        });
           
        },
}
}
</script>
<style lang="scss" scoped>
.shopList{
  overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
    .active {
       background: #2EC68A;
    border-color: #2EC68A;
    color: #FFFFFF;
  }
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .recordadd-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
 /deep/ .el-input-group__append .el-button--primary .el-icon-search{color: #fff}
 /deep/ .el-checkbox-button__inner{    border-left: 1px solid #DCDFE6;
    border-radius: 4px !important;}
     /deep/ .el-table.no-header .el-table__header{display: none}
  .people-message .el-form-item { margin-bottom: 0px;}
  .recordadd-upload{width: 160px;height: 160px;float: left;margin-right: 10px;
     .disabled{height: 100%;}
   /deep/ .el-upload{width: 160px;height: 160px;line-height: 160px;}
    /deep/ .el-upload-list--picture-card .el-upload-list__item{width: 160px;height: 160px;}
   }
   .health_url{
     /deep/ .el-upload--picture-card{width: 70px;height: 40px;
     background-color: transparent;
    border: none;
     .el-button--medium{position: absolute;left: 10px;margin-top: 4px;}}
/deep/ .el-upload-list--picture-card .el-upload-list__item{width: 70px;height: 40px;}
   } 
 table .cell .is-error{padding-bottom: 20px}
 .el-table th.is-leaf, .el-table td{border-bottom: none}
   /deep/ .disabled .el-upload--picture-card {
    display: none !important;
}
/deep/ .el-table{
.el-table__body,.el-table__header{width: 100% !important;}
} 
    .el-checkbox-button{margin-bottom: 10px}
}

</style>
